<template>
	<v-container class="font-size-24">
		<template>
			<v-row style="width: 1000px;height: 50px;margin-top: -50px;position: absolute;z-index: 999;">
				<v-col cols="10" style="padding: 0px  2px;">
					<v-btn-toggle v-model="btn_toggle" color="primary" variant="outlined">
						<v-btn v-for="(reasonTreeData, i) in triz.reasonTreeDatas" :key="`reasonTreeDatas-${i}-btn`">
							<span>因果链{{ i + 1 }}</span>
						</v-btn>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="addReasonTreeData()">mdi-plus-circle-outline</v-icon>
						<v-icon color="#1976d2" style="width: 25px;height: 25px;margin: 10px 2px;" size="x-large"
							@click="removeReasonTreeData()">mdi-minus-circle-outline</v-icon>
					</v-btn-toggle>
				</v-col>
			</v-row>
		</template>
		<template v-for="(reasonTreeData, i) in triz.reasonTreeDatas">
			<v-row :key="`reasonTreeData-${i}`" style="height: 570px;overflow-x: hidden;overflow-y: auto;" v-if="i == btn_toggle">
				<v-col>
					<div class="mr-8 Causal_tree" >
						<ReasonTree id="reasion-tree" v-model="triz.reasonTreeDatas[i]" @change="change" />
					</div>
				</v-col>
				
			</v-row>
		</template>
		<v-row align="center" justify="center" style="height: 20px;"> 图 系统的因果链关系图 </v-row>
		<v-row align="center" style="right: 70px;position: absolute;color: #1976d2;">tps:点击“节点”选择关键缺点</v-row>
	</v-container>
</template>

<script>
	import ReasonTree from '@/components/new_triz/ReasonTree.vue'
	import InnerTextField from '@/components/new_triz/InnerTextField'
	import ReasonTable from '@/components/new_triz/ReasonTable'

	export default {
		components: {
			ReasonTree,
			InnerTextField,
			ReasonTable,
		},
		props: {
			triz: {
				type: Object,
				default: () => ({}),
			},
		},
		model: {
			prop: 'triz',
			event: 'change',
		},
		data() {
			return {
				// triz: this.triz,
				btn_toggle: 0,
			}
		},

		mounted() {},
		methods: {
			addReasonTreeData() {
				// console.log('reasonTreeData',this.reasonTreeData)
				let reasonTreeDataId = new Date().getTime()
				this.triz.reasonTreeDatas.push({
					id: reasonTreeDataId,
					label: "初始缺点",
					logic:true,
				})
				this.change()
				this.btn_toggle = this.triz.reasonTreeDatas.length - 1
			},
			removeReasonTreeData() {
				const id = this.triz.reasonTreeDatas[this.btn_toggle].id
				const issue = this.triz.reasonTreeDatas.find((ele) => ele.id === id)
				if (issue) {
					if (this.btn_toggle > 0 && this.triz.reasonTreeDatas.length > 0) {
						this.btn_toggle = this.btn_toggle - 1
					} else {
						this.btn_toggle = 0
					}
					const index = this.triz.reasonTreeDatas.findIndex((ele) => ele.id === id)
					this.triz.reasonTreeDatas.splice(index, 1)
					this.change()
				}
			},

			change() {
				console.log('change')
				this.$emit('change', this.triz)
			},
			reasonChange(list) {
				console.log('reasonChange')
				this.triz.reasons = list
				this.change()
			},
			flatTreeData(tree) {
				const result = []
				if (!tree.children) return
				this.flatTree(tree.children, result)
				return result.filter((ele) => ele.selected)
			},
			flatTree(children, result) {
				for (let i = 0; i < children.length; i++) {
					const child = children[i]
					result.push(child)
					if (child.children) {
						this.flatTree(child.children, result)
					}
				}
			},
		},
	}
</script>
<style lang="scss">
	.Causal_tree {
		.v-btn:not(.v-btn--round).v-size--default {
			min-width: 10px;
			padding: 18px;
			border-radius: 25px;
		}
	}
</style>